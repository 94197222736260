<script>
  // core components
  // import { Link } from "svelte-routing";

  // core components
  import IndexNavbar from "components/Navbars/IndexNavbar.svelte";
  import Footer from "components/Footers/Footer.svelte";
  var fname =''
  var email =''
  var message =''
  var error =''
  var thankyou = ''
  async function sendMessage() {
    try {
      if (message && email && fname) {
        var posturl = "https://dexio.sabhyatech.com/v1/formdata";
        var payload = {
          user: "sabhyatech",
          id: "5edc2e966c88fd4008edb233",
          message: {
            name: fname,
            email: email,
            message: message,
          },
        };
        
        const response = await fetch(posturl, {
          method: "POST",
          body: JSON.stringify(payload),
          headers: {
            "Content-Type": "application/json",
          },
        });

        thankyou = `Thank You ${fname}, We will contact you shortly`;

        fname = "";
        email = "";

        return true;
      } else {
        thankyou = "";
        error = "Please select your options & Enter required details";
      }
    } catch (error) {
      error = "System is down at the moment. Please email or Call us directly";
    }
  }
</script>
<span><p>&nbsp;</p> </span><IndexNavbar /> <p>&nbsp;</p><p>&nbsp;</p><p>&nbsp;</p><p>&nbsp;</p>
<section class="relative block py-24 lg:pt-0 bg-blueGray-800">
  <div class="container mx-auto px-4">
    <div class="flex flex-wrap justify-center">
      <div class="w-full lg:w-6/12 px-4">
        <div
          class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-200"
        >
        
            <div class="flex-auto p-5 lg:p-10">
              <h4 class="text-2xl font-semibold">
                Want to work with us?
              </h4>
              <p class="leading-relaxed mt-1 mb-4 text-blueGray-500">
                Complete this form and we will get back to you shortly.
              </p>
              {#if thankyou == ""}
              <div class="relative w-full mb-3 mt-8">
                <label
                  class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                  for="full-name"
                >
                  Full Name
                </label>
                <input
                  id="full-name"
                  bind:value={fname}
                  type="text"
                  class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  placeholder="Full Name"
                />
              </div>

              <div class="relative w-full mb-3">
                <label
                  class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                  for="email"
                >
                  Email
                </label>
                <input
                  id="email"
                  bind:value={email}
                  type="email"
                  class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  placeholder="Email"
                />
              </div>

              <div class="relative w-full mb-3">
                <label
                  class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                  for="message"
                >
                  Message
                </label>
                <textarea
                  id="message"
                  bind:value={message}
                  rows="4"
                  cols="80"
                  class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full"
                  placeholder="Type a message..."
                />
              </div>
              <div class="text-center mt-6">
                <button
                  on:click|preventDefault={() => sendMessage()}
                  class="bg-blueGray-800 text-white active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                  type="button"
                >
                  Send Message
                </button>
                {#if error != ""}
                  <p>{error}</p>
                {/if}
              </div>
              {:else}
              <div>{thankyou}</div>
              {/if}
            </div>
        </div>
      </div>
    </div>
  </div>
</section>
<Footer />