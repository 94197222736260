<script>
  import { link } from "svelte-routing";

  // core components
  import IndexNavbar from "components/Navbars/IndexNavbar.svelte";
  import Footer from "components/Footers/Footer.svelte";

  
	const erplist = ['Microsoft', 'Oracle', 'Epicor', 'Odoo', 'ErpNext', 'Other'] 
  const proceslist = ['Order Entry', 'Job Closing', 'PO Entry', 'Kiosk', 'Compliance Audit', 'Other']
  const deploylist = ['Cloud', 'Devices As a Service', 'Hybrid'] 
	var selectederp = ''
  var selectedprocess = ''
  var selecteddeploy = ''
  var thankyou = ''
  var email =''
  var fname = ''
  var error = '';
  // export let location;

  function onSelectedItem (id, item ) {
    if (id == 'ERP') 
      selectederp=item
    if (id == 'PROC') 
      selectedprocess=item
    if (id == 'DEP') 
      selecteddeploy=item
  }
  
  async function sendMessage() {
    try {
      

    if (selectederp
				&& selectedprocess 
				&& selecteddeploy 
				&& email 
				&& fname) {
				
			var posturl = "https://dexio.sabhyatech.com/v1/formdata"; 
			var payload = {
					"user": "sabhyatech", 
					"id": "5edc2e966c88fd4008edb233", 
					"message" : {
					"name" : fname, 
					"email" : email, 
					"message" : `{ ERP ${selectederp
                  } ,\n Process: ${selectedprocess} \n , Deployment: ${selecteddeploy} }`  
					}
				}
        const response = await fetch(posturl, {
				method: 'POST',
				body: JSON.stringify(payload),
				headers: {
					'Content-Type': 'application/json'
				}
			  });
        
				thankyou = `Thank You ${fname}, We will contact you shortly for configuration specifics`; 

				fname = ""; 
				email = ""; 

				return true;
			}else {
				thankyou = ""; 
        error = 'Please select your options & Enter required details'
		}

    } catch (error) {
        error = 'System is down at the moment. Please email or Call us directly'  
    }

  }

</script>

<div>
  <IndexNavbar />
  <main>
    <section class="lg:pb-10 sm:pb-20 relative block bg-blueGray-800">
      <!-- <div
        class="bottom-auto top-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden -mt-20 h-20"
        style="transform: translateZ(0);"
      >
        <svg
          class="absolute bottom-0 overflow-hidden"
          xmlns="http://www.w3.org/2000/svg"
          preserveAspectRatio="none"
          version="1.1"
          viewBox="0 0 2560 100"
          x="0"
          y="0"
        >
          <polygon
            class="text-blueGray-800 fill-current"
            points="2560 0 2560 100 0 100"
          />
        </svg>
      </div> -->

      <div class="container mx-auto px-4 lg:pt-24">
        <div class="flex flex-wrap text-center justify-center">
          <div class="w-full lg:w-6/12 px-4">
            <h2 class="text-4xl font-semibold text-white">Build My Bot</h2>
            <p class="text-lg leading-relaxed mt-4 mb-4 text-blueGray-400">
              There are many reasons to use bots and machine vision. For
              example, you can use bots to automate business processes across
              multiple systems. You can also use machine vision to identify
              objects and trigger activities.
            </p>
          </div>
        </div>
      </div>
    </section>
    <section class="relative block lg:pt-0 bg-blueGray-800">
      <div class="container mx-auto">
        <div class="flex flex-wrap justify-center">
          <div class="w-full lg:w-8/12 px-4">
            <div
              class="relative flex flex-col min-w-0 b
              text-center justify-center
              reak-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-200"
            >
              <div class="flex-auto p-5  lg:p-10 ">
                <h4 class="text-2xl mb-3 font-semibold">Ready to build your bot?</h4>
                <p class="leading-relaxed mt-1 mb-4 text-blueGray-500">
                  Select your ERP or Primary System                </p>
                  <div  class="mt-5 justify-items-center" >
                    {#each erplist as item }
                    {#if item == selectederp}
                      <button on:click|preventDefault={() => onSelectedItem('ERP', item)}
                      target="_blank"
                      class="text-white font-bold px-4 py-4
                      rounded outline-none focus:outline-none mr-1 mb-1
                      bg-blueGray-700 active:bg-red-500 uppercase  
                      text-sm shadow hover:shadow-lg ease-linear transition-all duration-150"
                      >
                      {item}
                    </button>
                    {:else}
                    <button on:click|preventDefault={() => onSelectedItem('ERP', item)}
                      target="_blank"
                      class="text-white font-bold px-4 py-4
                      rounded outline-none focus:outline-none mr-1 mb-1
                      bg-red-400 active:bg-red-500 uppercase  
                      text-sm shadow hover:shadow-lg ease-linear transition-all duration-150"
                      >
                      {item}
                    </button>
                    {/if}
                  {/each}
                  </div>
                <p class="leading-relaxed mt-3 mb-2 text-blueGray-500">
                  Select your Business Process</p>
                  <div  class="mt-5 justify-items-center" >
                    {#each proceslist as item }
                    {#if item == selectedprocess}
                      <button   on:click|preventDefault={() => onSelectedItem('PROC', item)}
                      target="_blank"
                      class="text-white font-bold px-4 py-4
                      rounded outline-none focus:outline-none mr-1 mb-1
                      bg-blueGray-700 active:bg-red-500 uppercase  
                      text-sm shadow hover:shadow-lg ease-linear transition-all duration-150"
                      >
                      {item}
                    </button>
                    {:else}
                    <button on:click|preventDefault={() => onSelectedItem('PROC', item)}
                      target="_blank"
                      class="text-white font-bold px-4 py-4
                      rounded outline-none focus:outline-none mr-1 mb-1
                      bg-red-400 active:bg-red-500 uppercase  
                      text-sm shadow hover:shadow-lg ease-linear transition-all duration-150"
                      >
                      {item}
                    </button>
                    {/if}
                  {/each}
                  </div>
                  <p class="leading-relaxed mt-3 mb-2 text-blueGray-500">
                    Select your Preffered Deployment Model</p>
                    <div  class="mt-5 justify-items-center" >
                      {#each deploylist as item }
                      {#if item == selecteddeploy}
                        <button
                         on:click|preventDefault={() =>onSelectedItem('DEP', item)}
                        target="_blank"
                        class="text-white font-bold px-4 py-4
                        rounded outline-none focus:outline-none mr-1 mb-1
                        bg-blueGray-700 active:bg-red-500 uppercase  
                        text-sm shadow hover:shadow-lg ease-linear transition-all duration-150"
                        >
                        {item}
                      </button>
                      {:else}
                      <button 
                         on:click|preventDefault={() => onSelectedItem('DEP', item)}
                        target="_blank"
                        class="text-white font-bold px-4 py-4
                        rounded outline-none focus:outline-none mr-1 mb-1
                        bg-red-400 active:bg-red-500 uppercase  
                        text-sm shadow hover:shadow-lg ease-linear transition-all duration-150"
                        >
                        {item}
                      </button>
                      {/if}
                    {/each}
                    </div>
                  {#if thankyou == ''}
                  <div class="relative w-full mb-3 mt-8">
                    <!-- <label
                      class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      for="full-name"
                    >
                      Full Name
                    </label> -->
                    <input
                      id="full-name"
                      bind:value={fname}
                      type="text"
                      class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      placeholder="Full Name"
                    />
                  </div>
              
                <div class="relative w-full mb-3">
                  <!-- <label
                    class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    for="email"
                  >
                    Email
                  </label> -->
                  <input
                    id="email"
                    bind:value={email}
                    type="email"
                    class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    placeholder="Email"
                  />
                </div>

                <div class="text-center mt-6">
                 
                  <button
                    on:click|preventDefault={() =>sendMessage()}
                    class="bg-blueGray-800 text-white active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                  >
                    Build
                  </button>
                  {#if error != ''}
                    <p>{error}</p>
                  {/if}
                </div>
                {:else}
                <div>{thankyou}</div>
                {/if}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
  <Footer />
</div>
