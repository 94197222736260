<script>
  import { link } from "svelte-routing";

  // core components
  import IndexNavbar from "components/Navbars/IndexNavbar.svelte";
  import Footer from "components/Footers/Footer.svelte";

  // export let location;

  var fname =''
  var email =''
  var message =''
  var error =''
  var thankyou = ''
  async function sendMessage() {
    try {
      if (message && email && fname) {
        var posturl = "https://dexio.sabhyatech.com/v1/formdata";
        var payload = {
          user: "sabhyatech",
          id: "5edc2e966c88fd4008edb233",
          message: {
            name: fname,
            email: email,
            message: message,
          },
        };
        
        const response = await fetch(posturl, {
          method: "POST",
          body: JSON.stringify(payload),
          headers: {
            "Content-Type": "application/json",
          },
        });

        thankyou = `Thank You ${fname}, We will contact you shortly`;

        fname = "";
        email = "";

        return true;
      } else {
        thankyou = "";
        error = "Please select your options & Enter required details";
      }
    } catch (error) {
      error = "System is down at the moment. Please email or Call us directly";
    }
  }

</script>

<div>
  <IndexNavbar />
  <main>
    <section class="pb-20 relative block bg-blueGray-800">
      <div
        class="bottom-auto top-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden -mt-20 h-20"
        style="transform: translateZ(0);"
      >
        <svg
          class="absolute bottom-0 overflow-hidden"
          xmlns="http://www.w3.org/2000/svg"
          preserveAspectRatio="none"
          version="1.1"
          viewBox="0 0 2560 100"
          x="0"
          y="0"
        >
          <polygon
            class="text-blueGray-800 fill-current"
            points="2560 0 2560 100 0 100"
          />
        </svg>
      </div>

      <div class="container mx-auto px-4 lg:pt-24 lg:pb-64">
        <div class="flex flex-wrap text-center justify-center">
          <div class="w-full lg:w-6/12 px-4">
            <h2 class="text-4xl font-semibold text-white">
              <p>Hardware Products & Services</p>
              <p />
            </h2>
            <p class="text-lg leading-relaxed mt-4 mb-4 text-blueGray-400">
              Selecting right product for the application is critical when the
              feature set is exhaustive. Please review the product catalog and
              discuss with us about your application.
            </p>
          </div>
        </div>
        <div class="flex flex-wrap mt-12 justify-center">
          <div class="w-full lg:w-3/12 px-4 text-center">
            <a target="_new" href="assets/pdf/EyenorBrochure.pdf">
              <div
                class="text-blueGray-800 p-3 w-12 h-12 shadow-lg rounded-full bg-white inline-flex items-center justify-center"
              >
                <i class="fas fa-bullhorn text-xl" />
              </div>
              <h6 class="text-xl mt-5 font-semibold text-white">
                <p>Eyenor Brochure</p>
                <p />
              </h6>
              <p class="mt-2 mb-4 text-blueGray-400">
                Check out the range of options available to meet your security
                needs
              </p>
            </a>
          </div>
          <div class="w-full lg:w-3/12 px-4 text-center">
            <a target="_new" href="assets/pdf/productmetrix.pdf">
              <div
                class="text-blueGray-800 p-3 w-12 h-12 shadow-lg rounded-full bg-white inline-flex items-center justify-center"
              >
                <i class="fas fa-book text-xl" />
              </div>
              <h5 class="text-xl mt-5 font-semibold text-white">
                Eyenor Product Catalog
              </h5>
              <p class="mt-2 mb-4 text-blueGray-400">
                Check out our catalog for specific feature and model numbers
              </p>
            </a>
          </div>
        </div>
      </div>
    </section>
    <section class="relative block py-24 lg:pt-0 bg-blueGray-800">
      <div class="container mx-auto px-4">
        <div class="flex flex-wrap justify-center lg:-mt-64 -mt-48">
          <div class="w-full lg:w-6/12 px-4">
            <div
              class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-200"
            >
              <div class="flex-auto p-5 lg:p-10">
                <h4 class="text-2xl font-semibold">Interested In Our Security Products?</h4>
                <p class="leading-relaxed mt-1 mb-4 text-blueGray-500">
                  Complete this form and we will get back to you shortly
                </p>
                {#if thankyou == ""}
                <div class="relative w-full mb-3 mt-8">
                  <label
                    class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    for="full-name"
                  >
                    Full Name
                  </label>
                  <input
                    id="full-name"
                    bind:value={fname}
                    type="text"
                    class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    placeholder="Full Name"
                  />
                </div>

                <div class="relative w-full mb-3">
                  <label
                    class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    for="email"
                  >
                    Email
                  </label>
                  <input
                    id="email"
                    bind:value={email}
                    type="email"
                    class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    placeholder="Email"
                  />
                </div>

                <div class="relative w-full mb-3">
                  <label
                    class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    for="message"
                  >
                    Message
                  </label>
                  <textarea
                    id="message"
                    bind:value={message}
                    rows="4"
                    cols="80"
                    class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full"
                    placeholder="Please tell us a bit about your application and desired features. If you have model numbers from the catalog, please mention.."
                  />
                </div>
                <div class="text-center mt-6">
                  <button
                    class="bg-blueGray-800 text-white active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                  >
                    Send Message
                  </button>
                  {#if error != ""}
                    <p>{error}</p>
                  {/if}
                </div>
                {:else}
                <div>{thankyou}</div>
                {/if}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
  <Footer />
</div>
