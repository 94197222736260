<script>
  import { Link } from "svelte-routing";

  // core components
  import IndexNavbar from "components/Navbars/IndexNavbar.svelte";
  import Footer from "components/Footers/Footer.svelte";

  const patternVue = "/assets/img/pattern_svelte.png";
  const photo1 = "/assets/img/photo-149.jpg";
</script>

<span><p>&nbsp;</p> </span><IndexNavbar />
<section class="header relative pt-16 items-center flex h-screen max-h-860-px">
  <div class="container mx-auto items-center flex flex-wrap">
    <div class="w-full md:w-8/12 lg:w-6/12 xl:w-6/12 px-4">
      <div class="pt-32 sm:pt-0">
        <h2 class="font-semibold text-4xl text-blueGray-600">
          Business Focused Software & Hardware Solutions.
        </h2>
        <p class="mt-4 text-lg leading-relaxed text-blueGray-500">
          “We bring advanced software capabilities to companies with limited
          technology budgets”
          <!-- It features multiple HTML elements and it comes with dynamic
          components for ReactJS, Vue and Angular. -->
        </p>
        <div class="mt-12">
          <a
            href="/build"
            target="_blank"
            class="get-started text-white font-bold px-4 py-4 rounded outline-none focus:outline-none mr-1 mb-1 bg-red-400 active:bg-red-500 uppercase text-sm shadow hover:shadow-lg ease-linear transition-all duration-150"
          >
            Build My Bot
          </a>
          <a
            href="/app"
            class="github-star ml-1 text-white font-bold px-4 py-4 rounded outline-none focus:outline-none mr-1 mb-1 bg-blueGray-700 active:bg-blueGray-600 uppercase text-sm shadow hover:shadow-lg ease-linear transition-all duration-150"
            target="_blank"
          >
            Build My App
          </a>
          <!-- <a
            href="/app"
            class="github-star ml-1 text-white font-bold px-4 py-4 rounded outline-none focus:outline-none mr-1 mb-1 bg-red-800 active:bg-blueGray-600 uppercase text-sm shadow hover:shadow-lg ease-linear transition-all duration-150"
            target="_blank"
          >
            Buy Hardware
          </a> -->
        </div>
      </div>
    </div>
    <div class="mx-auto items-center w-full">
      <h3 class="text-3xl mb-2 font-semibold leading-normal">&nbsp;</h3>
      <div class="flex flex-wrap mt-10">
        <div class="w-full lg:w-4/12 px-2 sm:py-10">
          <h5 class="text-xl font-semibold pb-4 text-center">
            AI + Internet Of Things
          </h5>
          <Link to="/contact">
            <div
              class="hover:-mt-4 relative flex flex-col p-5 text-center
                min-w-0 break-words w-full mb-6
                shadow-lg rounded-lg ease-linear transition-all duration-150"
            >
              <p>Gain Insights and Control</p>
              <p>MODEL &diams; PRECISION &diams; CONTROL</p>
            </div>
          </Link>
        </div>
        <div class="w-full lg:w-4/12 px-4">
          <h5 class="text-xl font-semibold pb-4 text-center">
            Legacy Applications
          </h5>
          <Link to="/contact">
            <div
              class="hover:-mt-4 relative flex flex-col p-5 text-center
                min-w-0 break-words w-full mb-6
                shadow-lg rounded-lg ease-linear transition-all duration-150"
            >
              <p>Modernize, Integrate, Support</p>
              <p>DESIGN &diams; CONTAINERIZE &diams; MANAGE</p>
            </div>
          </Link>
        </div>

        <div class="w-full lg:w-4/12 px-4">
          <h5 class="text-xl font-semibold pb-4 text-center">
            Eliminate Bottlenecks
          </h5>
          <Link to="/contact">
            <div
              class="hover:-mt-4 relative flex flex-col p-5 text-center
              min-w-0 break-words w-full mb-6
              shadow-lg rounded-lg ease-linear transition-all duration-150"
            >
              <p>Effective Integrations, APIs</p>
              <p>BUSINESS PROCESS &diams; CONNECT &diams; PERFORM</p>
            </div>
          </Link>
        </div>
      </div>
    </div>
  </div>
  <img
    class="absolute top-0 b-auto right-0 pt-16 sm:w-6/12 -mt-48 sm:mt-0 w-10/12 max-h-860-px"
    src={patternVue}
    alt="..."
  />
</section>
<section class="mt-0 md:mt-40 pb-40 relative bg-blueGray-100">
  <div
    class="-mt-20 top-0 bottom-auto left-0 right-0 w-full absolute h-20"
    style="transform: translateZ(0);"
  >
    <svg
      class="absolute bottom-0 overflow-hidden"
      xmlns="http://www.w3.org/2000/svg"
      preserveAspectRatio="none"
      version="1.1"
      viewBox="0 0 2560 100"
      x="0"
      y="0"
    >
      <polygon
        class="text-blueGray-100 fill-current"
        points="2560 0 2560 100 0 100"
      />
    </svg>
  </div>
  <div class="container mx-auto">
    <div class="flex flex-wrap items-center">
      <div
        class="w-10/12 md:w-6/12 lg:w-4/12 px-12 md:px-4 mr-auto ml-auto -mt-32"
      >
        <div
          class="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded-lg bg-red-400"
        >
          <img
            alt="..."
            src={photo1}
            class="w-full align-middle rounded-t-lg"
          />
          <blockquote class="relative p-8 mb-4">
            <svg
              preserveAspectRatio="none"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 583 95"
              class="absolute left-0 w-full block h-95-px -top-94-px"
            >
              <polygon
                points="-30,95 583,95 583,65"
                class="text-red-400 fill-current"
              />
            </svg>
            <h4 class="text-xl font-bold text-white">
              Mobile/Web Apps, Cloud, ERP, Devices & anything in between..
            </h4>
            <p class="text-md font-light mt-2 text-white">
              We have experience building, deploying and managing custom
              solution built on proven technology stack.
            </p>
          </blockquote>
        </div>
      </div>

      <div class="w-full md:w-6/12 px-4">
        <div class="flex flex-wrap">
          <div class="w-full md:w-6/12 px-4">
            <div class="relative flex flex-col mt-4">
              <div class="px-4 py-5 flex-auto">
                <div
                  class="text-blueGray-500 p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-white"
                >
                  <i class="fas fa-sitemap" />
                </div>
                <h6 class="text-xl mb-1 font-semibold">
                  Cloud Migration, Modernization & Management Of Applications
                </h6>
                <p class="mb-4 text-blueGray-500">
                  Organizations looking to take advantage of the digital economy
                  are at times impaired from Legacy systems, siloed application,
                  data and infrastructure incapable of handling the volume and
                  variety.
                </p>
              </div>
            </div>
            <div class="relative flex flex-col min-w-0">
              <div class="px-4 py-5 flex-auto">
                <div
                  class="text-blueGray-500 p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-white"
                >
                  <i class="fas fa-drafting-compass" />
                </div>
                <h6 class="text-xl mb-1 capitalize font-semibold">
                  Better Utilization & Reduce Friction Across Business Functions
                </h6>
                <p class="mb-4 text-blueGray-500">
                  Leveraging open technologies including REST based services to
                  integrate business systems based on standardized business
                  processes and patterns applicable for the industry.
                </p>
              </div>
            </div>
          </div>
          <div class="w-full md:w-6/12 px-4">
            <div class="relative flex flex-col min-w-0 mt-4">
              <div class="px-4 py-5 flex-auto">
                <div
                  class="text-blueGray-500 p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-white"
                >
                  <i class="fas fa-newspaper" />
                </div>
                <h6 class="text-xl mb-1 capitalize font-semibold">
                  Low Cost - Low Maintenance Iot & Robotic Process Automation
                  (Rpa)
                </h6>
                <p class="mb-4 text-blueGray-500">
                  Using a pragmatic approach, we implement turn key solutions
                  involving sensors/machine interfaces, vision-based decision
                  support systems and advanced analytics including
                  implementation of AI/deep learning.
                </p>
              </div>
            </div>
            <div class="relative flex flex-col min-w-0">
              <div class="px-4 py-5 flex-auto">
                <div
                  class="text-blueGray-500 p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-white"
                >
                  <i class="fas fa-file-alt" />
                </div>
                <h6 class="text-xl mb-1 font-semibold">Hardware Solutions</h6>
                <p class="mb-4 text-blueGray-500">
                  Hardware we offer is from reputed brands, proven all over the
                  world in harsh conditions.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="container -mt-64 mx-auto overflow-hidden pb-20">
    <div class="flex flex-wrap items-center pt-32">
      <div class="w-full md:w-4/12 px-12 md:px-4 ml-auto mr-auto mt-48">
        <div
          class="text-blueGray-500 p-3 text-center inline-flex items-center justify-center w-16 h-16 mb-6 shadow-lg rounded-full bg-white"
        >
          <i class="fas fa-arrow-circle-right text-xl" />
        </div>
        <h3 class="text-3xl mb-2 font-semibold leading-normal">
          Testimonials & Results Delivered ..
        </h3>
        <p
          class="text-lg font-light leading-relaxed mt-4 mb-4 text-blueGray-600"
        >
          Sabhya Technologies delivered cutting edge solutions with high
          quality, saving time & money.
        </p>
      </div>
      <div class="w-full md:w-6/12 px-4 mr-auto ml-auto mt-32">
        <div class="justify-center flex flex-wrap relative">
          <div class="my-4 w-full lg:w-6/12 px-4">
            <a href="/contact" target="_blank">
              <div
                class="bg-black text-white shadow-lg rounded-lg text-center p-8"
              >
                <h6 class="text-xl mb-1 font-semibold">Checklist Solution</h6>
                <div>
                  <p>
                    Device As a Service (DaaS) deployed for Production Quality
                    Control Improved Compliance, Data driven decision on QC
                  </p>
                </div>
                <div class="font-semibold">
                  "Manufacturer of High-end sports equipment"
                </div>
              </div>
            </a>
            <a href="/contact" target="_blank">
              <div
                class="bg-lightBlue-500 shadow-lg rounded-lg text-center p-8 mt-8"
              >
                <h6 class="text-xl mb-1 font-semibold">IoT Application</h6>
                <div>
                  <p>
                    Custom device with sensors and cloud connectivity for safety
                  </p>
                </div>
                <div class="font-semibold">"Oil & Gas Device Manufacturer"</div>
              </div>
            </a>
          </div>
          <div class="my-4 w-full lg:w-6/12 px-4 lg:mt-16">
            <a href="/contact" target="_blank">
              <div class="bg-yellow-500 shadow-lg rounded-lg text-center p-8">
                <h6 class="text-xl mb-1 font-semibold">Mobile App</h6>
                <div>
                  <p>
                    Custom Mobile App integrated with regulated Enterprise
                    applications
                  </p>
                </div>
                <div class="font-semibold">"Health care organization"</div>
              </div>
            </a>
            <a href="/" target="_blank">
              <div
                class="bg-red-700 text-white shadow-lg rounded-lg text-center p-8 mt-8"
              >
                <h6 class="text-xl mb-1 font-semibold">
                  Custom Mobile Apps & Firmware
                </h6>
                <div>
                  <p>
                    Developed Mobile app and Firmware, Enabling bluetooth
                    connectivity and cloud storage
                  </p>
                </div>
                <div class="font-semibold">
                  "Electronics parts Manufacturer"
                </div>
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<section class="pb-16 bg-blueGray-400 relative pt-12 ">
  <div class="flex flex-wrap items-center ">
    <div class="w-full md:w-8/12 ml-auto mr-auto">
      <div>
        <h2  class="font-semibold text-3xl">
           What are the most common technology issues for small manufacturing
          companies?  
        </h2>
        <p>&nbsp;</p>
        <p>
         "There are many technology issues that small manufacturing companies
          face in today’s digital economy. Some of the most common ones are:
        </p>
        <ul>
          <li class="p-2">
           <b> Cybersecurity:</b> Small manufacturers need to protect their data and
            systems from cyberattacks, which can cause financial losses,
            reputational damage, and operational disruptions. A cybersecurity
            plan that includes risk assessment, safeguards, and incident
            response is essential for any digital transformation.
          </li>
          <li class="p-2">
          <b>Capital:</b> Investing in advanced manufacturing technologies such as
            automation, robotics, IoT, and AI can be costly and require
            long-term planning. Small manufacturers need to balance the
            benefits and costs of digitization, and consider the opportunity
            cost of not adopting new technologies.
          </li>
          <li class="p-2">
           <b> Learning curve:</b> Implementing and using new technologies can be
            challenging for small manufacturers who may lack the relevant
            knowledge, skills, and abilities. They may need to seek external
            expertise, training, and support to overcome the learning curve
            and achieve their digital goals.
          </li>
          <li class="p-2">
            <b> Legacy systems: </b> Many small manufacturers rely on outdated
            software, hardware, and processes that are incompatible with new
            technologies and customer expectations. They may need to upgrade
            or replace their legacy systems to improve their efficiency,
            agility, and competitiveness.
          </li>
          <li class="p-2">
            <b>Decision making:</b> Digitization requires a change in how small
            manufacturers make decisions. They need to leverage data and
            analytics to gain insights, optimize performance, and innovate.
            They also need to foster a culture of data-driven decision making
            that encourages experimentation, collaboration, and learning."
          </li>
        </ul>
        <p>&nbsp;</p>
        <p>Do you Agree with Gen AI?</p>
      </div>
    </div>
  </div>
</section>

<section class="pb-16 bg-blueGray-400 relative pt-32">
  <div
    class="-mt-20 top-10 bottom-auto left-0 right-0 w-full absolute h-20"
    style="transform: translateZ(0);"
  >
    <svg
      class="absolute bottom-0 overflow-hidden"
      xmlns="http://www.w3.org/2000/svg"
      preserveAspectRatio="none"
      version="1.1"
      viewBox="0 0 2560 100"
      x="0"
      y="0"
    >
      <polygon
        class="text-blueGray-200 fill-current"
        points="2560 0 2560 100 0 100"
      />
    </svg>
  </div>

  <div class="container mx-auto">
    <div
      class="flex flex-wrap justify-center bg-white shadow-xl rounded-lg -mt-48 py-16 px-12 relative z-10"
    >
      <div class="w-full text-center lg:w-8/12">
        
        <h3 class="font-semibold text-3xl">Interested in learning more?</h3>
        <div class="sm:block flex flex-col mt-10 mb-4">
          <a
            href="/contact"
            target="_blank"
            class="get-started text-white font-bold px-6
            py-4 rounded outline-none focus:outline-none mr-1 mb-2 bg-red-400 active:bg-red-500 uppercase text-sm shadow hover:shadow-lg ease-linear transition-all duration-150"
          >
            Contact Us
          </a>
        </div>
      </div>
    </div>
  </div>
</section>
<Footer />
